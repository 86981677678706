import React, { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import CardsFallBackImage from '../CardsFallBackImage/CardsFallBackImage';
import classnames from 'classnames';
import styles from './index.module.scss';

interface CardsImageProps {
  title: string;
  id: number;
  href: string;
  gridType?: 'webcasts' | 'summits' | 'series' | 'channels' | 'virtualEvents';
  pageType?: string // need to be able to check what page i.e search so we can build new image params
  imageType?: 'logo' | 'full';
  priorityStatus?: boolean;
}

const CardsImage: FC<CardsImageProps> = ({ title, id, href, gridType, pageType, imageType, priorityStatus }) => {
  const [imgSrc, setImgSrc] = useState(href);

  useEffect(() => {
    setImgSrc(href);
  }, [href]);

  let layoutFill;
  let imageWidth;
  let imageHeight;
  //let objectFit;
  //Added to allow additional classes to style items such as featured series
  let gridTypeClass;
  let fallbackSrc;

  // Set a default sizes property value
  let srcsetSizes = "(max-width: 760px) 100vw, (max-width: 1200px) 50vw, (max-width: 1400px) 33vw";


  // Build an approach to changing the object fit keyword to suit series/summits full images
  // hero banner need to be displayed as cover and cropped NOT as contain
  let objectFitType
  if (gridType === 'summits' || gridType === 'series' || pageType === 'section-featured') {
   objectFitType = "cover";
  } else {
   objectFitType = "contain";
  }


  if ( ( gridType === 'summits' || gridType === 'series' ) && pageType != 'search-page') {
    layoutFill = '';
    objectFitType = 'contain';
    imageWidth  = '600';
    imageHeight = '200';
    fallbackSrc = '/portal/placeholders/diamond-cube-svg16-9.svg';

  } else if (pageType === 'section-featured') {
    imageWidth  = '455';
    imageHeight = '255';
    gridTypeClass = 'section-featured';
    fallbackSrc = '/portal/placeholders/diamond-cube.svg';
    srcsetSizes =  "(max-width: 760px) 100vw, (max-width: 1400px) 50vw, 33vw";
  } else {
    layoutFill  = '';
    imageWidth  = '334';
    imageHeight = '190';
    gridTypeClass = '';
    fallbackSrc = '/portal/placeholders/diamond-cube.svg';
    srcsetSizes =  "(max-width: 760px) 100vw, (max-width: 1400px) 50vw, 33vw";
  }

  return (
    <>
      { imgSrc ? (
        <Image
          className={classnames( styles[pageType], styles[gridType], styles['card-image-el'], styles[gridTypeClass] ) }
          key={`item-image-` + id}
          src={imgSrc}
          quality={70}
          // Priority sets whether image preloaded and disables lazy loading if it's true'
          // Above fold largest contentful paint should preload NOT lazy load! i.e true
          // default value is false to ensure grids lazyload
          priority={priorityStatus}
          alt={title}
          width={imageWidth}
          height={imageHeight}
          sizes={srcsetSizes}
          placeholder={'empty'}
          //blurDataURL={}
          onError={() => {
            setImgSrc( fallbackSrc );
          }}
        />
      ) : (
        <CardsFallBackImage gridType={gridType} title={title} pageType={pageType} imageType={imageType} priorityStatus={priorityStatus}  />
      )}
    </>
  );
};

export default CardsImage;
